import moment from 'moment';
import { IAvailabilitySlot, ITeamMemberAvailabilities } from './types';

export type Breakpoint = 'mobile' | 'desktop';

export const getMonthFromOffset = (offset: number) => {
	const current = moment().utc();
	if (offset > 0) {
		current.add(offset, 'month');
	} else if (offset < 0) {
		current.subtract(Math.abs(offset), 'month');
	}
	return current;
};

export const getSelectedDateAsString = (selectedDate: moment.Moment, breakpoint: Breakpoint) => {
	if (!selectedDate) {
		return null;
	}

	const mobileFormat = 'dddd, MMMM Do, YYYY';
	const desktopFormat = 'dddd, MMM D';
	return selectedDate.format(breakpoint === 'mobile' ? mobileFormat : desktopFormat);
};

export const getDurationText = (duration: string) => {
	let time = duration;
	let days = 0;
	let hours = 0;
	let minutes = 0;

	if (duration.includes('.')) {
		const [d, t] = duration.split('.');
		days = parseInt(d, 10);
		time = t;
	}

	const [h, m] = time.split(':');
	hours = parseInt(h, 10);
	minutes = parseInt(m, 10);

	return `${days ? `${days} day${days > 1 ? 's' : ''} ` : ''}${hours ? `${hours} hour${hours > 1 ? 's' : ''} ` : ''}${
		minutes ? `${minutes} minute${minutes > 1 ? 's' : ''}` : ''
	}`;
};

export function calculateTeamTimeSlots(teamAvailabilities: ITeamMemberAvailabilities[], selectedDate: moment.Moment) {
	if (!teamAvailabilities?.length || !selectedDate) {
		return [];
	}
	let teamTimeSlots: IAvailabilitySlot[] = [];
	const availableAvailabilities = teamAvailabilities
		.filter(a => a?.success)
		.sort((a, b) => a.config.priority - b.config.priority);

	for (const teamMemberAvailabilities of availableAvailabilities) {
		const newTimeSlots = (teamMemberAvailabilities.availabilities[selectedDate.date() - 1] || [])?.map(a => {
			return {
				ids: [teamMemberAvailabilities.config.id],
				priority: teamMemberAvailabilities.config.priority,
				time: moment(a.start),
			};
		});
		if (teamTimeSlots.length === 0) {
			teamTimeSlots = [...newTimeSlots];
		} else {
			for (const newTimeSlot of newTimeSlots) {
				const searchTime = moment(newTimeSlot.time);
				/* Always show overflow slots
				if (newTimeSlot.time.minutes() === 15) {
					searchTime.add(-15, 'minute');
				}
				 */
				const i = teamTimeSlots.findIndex(x => x.time.isSame(searchTime));
				if (i >= 0) {
					if (newTimeSlot.priority < teamTimeSlots[i].priority) {
						teamTimeSlots[i] = newTimeSlot;
					} else if (newTimeSlot.priority === teamTimeSlots[i].priority) {
						const multiId = teamTimeSlots[i];
						multiId.ids.push(newTimeSlot.ids[0]);
						teamTimeSlots[i] = { ...multiId };
					}
				} else {
					teamTimeSlots.push(newTimeSlot);
				}
			}
		}
	}
	teamTimeSlots.sort((a, b) => (a.time.isAfter(b.time) ? 1 : -1));
	return teamTimeSlots;
}
