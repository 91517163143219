import { useLambda } from '../../../../../models/hooks/useLambda';
import { header } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	hoverColor?: string;
}

export const LocationPinIcon: React.FC<IProps> = ({ className = '', fillColor = header, hoverColor = header }) => {
	const [hover, , setHover] = useLambda(false);
	const stroke = hover ? hoverColor : fillColor;

	return (
		<SvgIcon
			onMouseEnter={setHover(true)}
			onMouseLeave={setHover(false)}
			className={className || ''}
			width={16}
			height={21}
			viewBox='0 0 16 21'
		>
			<path
				fill='none'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1 8C1 12.2875 8 19.375 8 19.375C8 19.375 15 12.2875 15 8C15 3.5375 11.4125 1 8 1C4.5875 1 1 3.5375 1 8V8Z'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fill='none'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 5.375C7.3 5.375 5.375 5.875 5.375 7.875C5.375 8.70833 6.5125 10.4583 8 12.375C9.4875 10.4583 10.625 8.70833 10.625 7.875C10.625 5.875 8.7 5.375 8 5.375V5.375Z'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</SvgIcon>
	);
};
