import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const OopsNotFoundGraphic: React.FunctionComponent<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`oops-not-found-graphic ${className || ''}`} height={180} width={180}>
			<circle cx='90' cy='90' r='90' fill='#E8F3F9' />
			<g clipPath='url(#Group__clip0)'>
				<path
					d='M29.08 76.569l44-36.667a3 3 0 013.84 0l44.001 36.667A3 3 0 01122 78.874V131a3 3 0 01-3 3H31a3 3 0 01-3-3V78.874a3 3 0 011.08-2.305z'
					fill='#CAE0EC'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path fill='#fff' stroke='#00AAE8' strokeWidth='2' d='M39 49h70v63H39z' />
				<path
					d='M68.613 74.705c0 1.371-.347 2.45-1.043 3.234-.691.786-1.654 1.178-2.888 1.178-1.262 0-2.237-.385-2.924-1.154-.684-.773-1.026-1.863-1.026-3.27 0-1.394.344-2.474 1.032-3.24.687-.77 1.664-1.154 2.93-1.154 1.23 0 2.19.39 2.882 1.172.692.781 1.037 1.86 1.037 3.234zm-6.826 0c0 1.16.246 2.041.738 2.643.497.597 1.215.896 2.157.896.949 0 1.666-.299 2.15-.896.484-.598.727-1.479.727-2.643 0-1.152-.243-2.025-.727-2.62-.48-.597-1.193-.895-2.139-.895-.949 0-1.671.3-2.168.902-.492.597-.738 1.469-.738 2.613zm17.206 0c0 1.371-.347 2.45-1.043 3.234-.691.786-1.654 1.178-2.888 1.178-1.262 0-2.237-.385-2.924-1.154-.684-.773-1.025-1.863-1.025-3.27 0-1.394.343-2.474 1.03-3.24.688-.77 1.665-1.154 2.93-1.154 1.231 0 2.192.39 2.883 1.172.692.781 1.037 1.86 1.037 3.234zm-6.826 0c0 1.16.246 2.041.739 2.643.496.597 1.214.896 2.156.896.949 0 1.666-.299 2.15-.896.484-.598.727-1.479.727-2.643 0-1.152-.242-2.025-.727-2.62-.48-.597-1.193-.895-2.139-.895-.949 0-1.671.3-2.167.902-.493.597-.739 1.469-.739 2.613zM87.37 72.93c0 .867-.297 1.535-.891 2.004-.59.464-1.436.697-2.537.697h-1.008V79h-.996v-8.566h2.22c2.141 0 3.212.832 3.212 2.496zm-4.436 1.845h.897c.882 0 1.521-.142 1.916-.427.394-.285.591-.742.591-1.371 0-.567-.185-.989-.556-1.266-.371-.277-.95-.416-1.735-.416h-1.113v3.48zm12.097 1.946c0 .754-.273 1.341-.82 1.763-.547.422-1.29.633-2.227.633-1.015 0-1.797-.13-2.344-.392v-.961c.352.148.735.265 1.149.351.414.086.824.13 1.23.13.664 0 1.164-.126 1.5-.376.336-.254.504-.605.504-1.055 0-.296-.06-.539-.181-.726-.118-.191-.317-.367-.598-.527-.277-.16-.701-.342-1.272-.545-.796-.285-1.367-.623-1.71-1.014-.34-.39-.51-.9-.51-1.53 0-.66.248-1.185.744-1.575.496-.391 1.152-.587 1.969-.587.851 0 1.634.157 2.35.47l-.311.867c-.707-.297-1.395-.446-2.063-.446-.527 0-.94.114-1.236.34-.297.227-.445.541-.445.943 0 .297.054.541.164.733.109.187.293.361.55.521.262.156.66.33 1.196.522.898.32 1.515.664 1.851 1.031.34.367.51.844.51 1.43z'
					fill='#858585'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M28 81v49a4 4 0 004 4h66L28 81z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M122.01 81v49a4 4 0 01-4 4h-66l70-53z'
					fill='#E8F3F9'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M58 105l-23 28 30-23-7-5zm34 0l23 28-30-23 7-5z'
					fill='#CAE0EC'
				/>
				<path
					d='M76.987 100.09L115.358 134H34.642l38.371-33.91a3 3 0 013.974 0z'
					fill='#fff'
					stroke='#00AAE8'
					strokeWidth='2'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M72.626 106.727a4 4 0 014.748 0L113 133H37l35.626-26.273z'
					fill='#E8F3F9'
				/>
			</g>
			<path stroke='#00AAE8' d='M119.5 139.5h29v1h-29z' />
			<path
				d='M116 100h36v25c0 8.284-6.716 15-15 15h-6c-8.284 0-15-6.716-15-15v-25z'
				fill='#CAE0EC'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M164.184 108H152v5h7.788c.117 0 .212.095.212.212v.726a9.132 9.132 0 01-8 9.062v5c7.18 0 13-5.82 13-13v-6.184a.816.816 0 00-.816-.816z'
				fill='#CAE0EC'
			/>
			<path
				d='M152 108v-1h-1v1h1zm0 5h-1v1h1v-1zm0 10l-.124-.992-.876.109V123h1zm0 5h-1v1h1v-1zm0-19h12.184v-2H152v2zm1 4v-5h-2v5h2zm-1 1h7.788v-2H152v2zm7.788 0a.788.788 0 01-.788-.788h2c0-.669-.543-1.212-1.212-1.212v2zm-.788-.788v.726h2v-.726h-2zm0 .726a8.133 8.133 0 01-7.124 8.07l.248 1.984A10.132 10.132 0 00161 113.938h-2zM153 128v-5h-2v5h2zm11-13c0 6.627-5.373 12-12 12v2c7.732 0 14-6.268 14-14h-2zm0-6.184V115h2v-6.184h-2zm.184.184a.184.184 0 01-.184-.184h2a1.816 1.816 0 00-1.816-1.816v2z'
				fill='#00AAE8'
			/>
			<path d='M120 101h28v20c0 7.732-6.268 14-14 14s-14-6.268-14-14v-20z' fill='#E8F3F9' />
			<mask id='a' maskUnits='userSpaceOnUse' x='120' y='101' width='28' height='34'>
				<path d='M120 101h28v20c0 7.732-6.268 14-14 14s-14-6.268-14-14v-20z' fill='#fff' />
			</mask>
			<g mask='url(#a)'>
				<path fill='#F2FAFF' d='M135 101h6v34h-6z' />
			</g>
			<path d='M136 78s-3 1.95-3 5c0 2.95 2.727 4 3 7 .227 2.5-3 6-3 6' stroke='#00AAE8' strokeWidth='2' fill='#FFF' />
			<defs>
				<clipPath id='Group__clip0'>
					<path fill='#fff' transform='translate(27 37)' d='M0 0h96v98H0z' />
				</clipPath>
			</defs>
		</SvgIcon>
	);
};
